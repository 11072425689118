var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-card',{staticStyle:{"background-color":"var(--v-toolbar-lighten1) !important","border-radius":"20px"},attrs:{"flat":""}},[_c('v-card-text',[_c('v-list',{staticStyle:{"height":"80vh","overflow-y":"auto"},attrs:{"dense":"","subheader":""}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"search","placeholder":"Search","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-subheader',{staticStyle:{"font-size":"14px"}},[_vm._v(" Containers ")]),_c('v-divider'),_vm._l((_vm.containers),function(container){return _c('v-list-item',{key:container.id,style:({
                    'border-left':
                      _vm.selectedContainer &&
                      _vm.selectedContainer.id == container.id
                        ? '3px solid var(--v-primary-base)'
                        : '3px solid transparent',
                  }),on:{"click":function($event){return _vm.selectContainer(container)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(container.ctoNo)+" ")]),(container.containerNo)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(container.containerNo)+" ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(container.containerType)+" ")])],1),_c('v-list-item-action',[_c('v-progress-circular',{attrs:{"value":container.assignedProgress,"size":"20","color":container.assignedProgress < 20
                          ? 'red'
                          : container.assignedProgress < 50
                          ? 'orange'
                          : container.assignedProgress < 100
                          ? 'blue'
                          : 'success'}})],1)],1)})],2)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"9"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }