<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <v-card
              flat
              style="
                background-color: var(--v-toolbar-lighten1) !important;
                border-radius: 20px;
              "
            >
              <v-card-text>
                <v-list dense subheader style="height: 80vh; overflow-y: auto">
                  <v-text-field
                    prepend-inner-icon="search"
                    v-model="search"
                    placeholder="Search"
                    outlined
                    dense
                    clearable
                    hide-details
                  ></v-text-field>
                  <v-subheader style="font-size: 14px">
                    Containers
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="container in containers"
                    :key="container.id"
                    @click="selectContainer(container)"
                    :style="{
                      'border-left':
                        selectedContainer &&
                        selectedContainer.id == container.id
                          ? '3px solid var(--v-primary-base)'
                          : '3px solid transparent',
                    }"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ container.ctoNo }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="container.containerNo">
                        {{ container.containerNo }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ container.containerType }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-progress-circular
                        :value="container.assignedProgress"
                        size="20"
                        :color="
                          container.assignedProgress < 20
                            ? 'red'
                            : container.assignedProgress < 50
                            ? 'orange'
                            : container.assignedProgress < 100
                            ? 'blue'
                            : 'success'
                        "
                      ></v-progress-circular>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" md="9"> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["order", "containers"],
  data: () => ({
    search: "",
    selectedContainer: null,
  }),
  methods: {
    selectContainer(container) {
      this.selectedContainer = container;
    },
  },
};
</script>